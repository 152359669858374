@tailwind utilities;

.footer {
    font-size: .6rem;
    @apply p-1 absolute bottom-1 font-mono right-1/2 translate-x-1/2;
    @apply md:right-1 md:translate-x-0;
}

.kupferstich-bg {
    background-color: #833ab4;
    background: linear-gradient(90deg, #833ab4 0%, #fd1d1d 100%);

    @apply relative w-10/12  border-2 rotate-6 m-auto aspect-video shadow-2xl shadow-black;
    @apply md:w-2/3 md:border-8;
}

.title {
    @apply absolute pointer-events-none flex flex-col justify-center items-center z-10
    p-0 left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 -rotate-6 text-center;
}

.kupferstich-bg h1 {
    --color: #000;
    text-shadow: 0 0 2px var(--color), 0 0 1em var(--color), 0 0 0.2em var(--color);
    @apply leading-5 mb-2 text-white sm:text-2xl md:text-4xl;
}

.kupferstich-bg h2 {
    --color: #000;
    text-shadow: 0 0 2px var(--color), 0 0 1em var(--color), 0 0 0.2em var(--color);
    @apply leading-3 text-xs sm:text-xl md:text-2xl;
}

.kupferstich-bg::before {
    content: '';
    opacity: 0.5;
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background: url("../public/images/kupferstich.webp");
    background-size: cover;
    background-blend-mode: screen;
}


.link-container {
    @apply gap-1 flex absolute bottom-1 right-1;
}

.link {
    background-color: rgba(162, 141, 107, 0.5);
    @apply text-xs text-black border-black border rounded px-2 py-1 backdrop-blur;
}
